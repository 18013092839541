import { ComponentProps, FC, ForwardedRef, MutableRefObject } from "react";
import * as _ from "lodash";
import Markdown from "react-markdown";

import {
  Analysis,
  Button,
  Charts,
  CloseOutlined,
  Col,
  FullscreenButton,
  Grid,
  Icon,
  Popover,
  Row
} from "@ctra/components";

import { Enterprise as Content, useTranslation } from "@ctra/i18n";
import { ChartEntity, Enterprise } from "@ctra/api";
import { GAEvent } from "@ctra/analytics";
import { useChartContext } from "@ctra/charts";
import { isMutableRef, resizeBase64Image } from "@ctra/utils";

import { ChartProps, ChartViewFilter, useChartAPI, useChartFilters } from "@chart";
import { GACategories } from "@analytics";

import { AnomalyToggle } from "../AnomalyToggle";
import { testIds } from "../testing";
import styles from "./ChartExtra.module.less";

export type ChartExtraProps = Omit<ChartProps, "filters">;

const {
  chart: { compare },
  analytics: {
    feedback: { title }
  }
} = Content;

/**
 * Extra tools for chart components: full screen/chart comparison
 * @param {(() => void) | undefined} handleCompare
 * @param {(() => void) | undefined} handleClose
 * @param {{}} viewOptions
 * @param {ChartVariant | undefined} variant
 * @param {Partial<Pick<ContainerConfig<Options, Plot<O>>, "onReady" | "onEvent"> & Options> | undefined} config
 * @returns {JSX.Element}
 * @constructor
 */
export const ChartExtra: FC<ChartExtraProps> = ({
  handleCompare,
  handleClose,
  viewOptions = {},
  variant
}) => {
  const { t } = useTranslation();
  const { md } = Grid.useBreakpoint();
  const { ref } = useChartContext();

  const {
    chart,
    meta,
    api,
    dataDescriptor: {
      dataProperties: { typeName }
    }
  } = useChartAPI();

  const {
    timePeriod,
    metadata: { supportedfarmIDs: farmIDs }
  } = useChartFilters();

  const { setChart } = api;
  const { supportedViews } = meta;
  const { view } = chart as ChartEntity;

  /**
   * Handle chart view changes
   * @param view
   */
  const onViewChange: ComponentProps<typeof ChartViewFilter>["onViewChange"] = (view) => {
    GAEvent(GACategories.configurator, "Change chart view", JSON.stringify({ view }));

    setChart(supportedViews[view]);
  };

  return (
    <Row
      justify="space-around"
      gutter={[0, 0]}
      align="middle"
      className={styles.Wrapper}
      onClick={(evt) => {
        evt.stopPropagation();
      }}
    >
      {viewOptions.showAnnotations && (_.isEqual(variant, Charts.ChartVariant.V3) || viewOptions.zoomed) && (
        <Col pull={2}>
          <AnomalyToggle />
        </Col>
      )}
      <Col pull={1}>
        <Button
          type={"primary"}
          disabled={!isMutableRef<any>(ref)}
          onClick={async () => {
            if (isMutableRef<any>(ref)) {
              const base64 = await resizeBase64Image(ref.current.toDataURL("image/png"), 1280);

              Enterprise.history.push({
                state: {
                  feedback: {
                    sourceID: chart.id,
                    sourceType: "Chart",
                    context: {
                      timePeriod,
                      typeName
                    },
                    base64Content: base64
                  }
                }
              });
            }
          }}
        >
          {t(title)}
        </Button>
      </Col>
      {viewOptions.showSupportedViews && _.keys(supportedViews).length > 1 && (
        <Col className={styles.ViewFilter}>
          <ChartViewFilter views={_.keys(supportedViews)} view={view} onViewChange={onViewChange} />
        </Col>
      )}
      {handleCompare && farmIDs && (
        <Popover content={<Markdown>{t<string>(compare)}</Markdown>} placement="topRight" overlayClassName={styles.Popover}>
          <Button
            data-testid={testIds.chartExtra.compareCharts}
            onClick={() => {
              handleCompare();
            }}
            icon={<Icon component={Analysis} />}
          />
        </Popover>
      )}
      {/* exit full screen button */}
      {viewOptions.zoomed && md && (
        <FullscreenButton
          exit={true}
          data-testid={testIds.chartExtra.zoom}
          onClick={() => {
            Enterprise.history.push({
              state: {}
            });
          }}
        />
      )}
      {_.isFunction(handleClose) && <CloseOutlined className={styles.Close} onClick={handleClose} />}
    </Row>
  );
};
