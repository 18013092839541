import moment from "moment";
import { useLocation } from "react-router-dom";
import React from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";

import {
  Button,
  Space,
  MediaQueryDynamicImport,
  Spin,
  Typography,
  Form,
  Input,
  DatePicker,
  Result,
  CloseOutlined
} from "@ctra/components";

import { ChartTimePeriod, Enterprise, EnterpriseAppState, Generic } from "@ctra/api";
import { useTranslation, Enterprise as Content } from "@ctra/i18n";
import { isFulfilled, Optional, TS } from "@ctra/utils";

import { URIState } from "@routes";
import { testIds } from "@chart";
import { DataDictionaryContext, useLocalization } from "@base";
import { useFarm } from "@farms";
import { EventListContext } from "@events";

import styles from "./ChartFeedback.module.less";

const { Paragraph } = Typography;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const {
  kpi: { displayName },
  analytics: { feedback: feedbackCopy }
} = Content;

interface FeedbackContext {
  typeName: string;
  timePeriod: ChartTimePeriod;
}

/**
 * Chart feedback component
 * @constructor
 */
export const ChartFeedback = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { farm } = useFarm();
  const dispatch = useDispatch();
  const { state } = useLocation<URIState>();
  const { farm: farmContext } = useFarm();
  const { dateFormat } = useLocalization();

  /**
   * Tell if the feedback is submitted
   * @type {boolean}
   */
  const isSubmitted = useSelector<EnterpriseAppState, boolean>((state) =>
    isFulfilled(state, Generic.types.SEND_FEEDBACK)
  );

  if (state?.feedback) {
    const { sourceID, context, sourceType, base64Content } = state.feedback;

    const { timePeriod, typeName } = _.defaultTo<FeedbackContext>(context as Optional<FeedbackContext>, {
      typeName: "",
      timePeriod: {
        startDate: TS.now(),
        endDate: TS.now()
      }
    });

    return sourceID ? (
      <div data-testid={testIds.chartEdit.wrapper}>
        <MediaQueryDynamicImport mobile={() => import("./Mobile")} desktop={() => import("./Desktop")}>
          {(Component, { isMobile }) => (
            <DataDictionaryContext.Provider>
              <EventListContext.Provider farmID={farmContext?.id}>
                <Formik<{
                  base64Content: string;
                  dateRange: [moment.Moment, moment.Moment];
                  text: string;
                }>
                  initialValues={{
                    base64Content: base64Content,
                    dateRange: [moment(timePeriod.startDate), moment(timePeriod.endDate)],
                    text: ""
                  }}
                  onSubmit={({ base64Content, dateRange: [start, end], text }, { setSubmitting }) => {
                    const payload = {
                      context: {
                        sourceId: sourceID,
                        sourceType: sourceType,
                        farmId: farm?.id,
                        startAt: start.format("YYYY-MM-DDTHH:mm:ss"),
                        endAt: end.format("YYYY-MM-DDTHH:mm:ss")
                      },
                      type: "IssueReport",
                      name: `Chart issue report: ${t<string>(displayName(typeName), {
                        case: null,
                        variant: null,
                        makeDefaultValue: true
                      })}`,
                      text,
                      attachments: [
                        {
                          name: t<string>(displayName(typeName), {
                            case: null,
                            variant: null,
                            makeDefaultValue: true
                          }),
                          base64Content
                        }
                      ]
                    };

                    dispatch(Generic.actions.sendFeedback.start(payload));
                  }}
                >
                  {({
                    values: {
                      dateRange: []
                    },
                    submitCount,
                    isSubmitting
                  }) => (
                    <Component
                      centered
                      visible
                      title={submitCount > 0 && isSubmitted ? void 0 : t<string>(feedbackCopy.title)}
                      footer={null}
                      closable={!(submitCount > 0 && isSubmitted)}
                      closeIcon={submitCount > 0 && isSubmitted ? <CloseOutlined /> : null}
                      bodyStyle={{ height: "100%", padding: 0 }}
                      onCancel={() => {
                        Enterprise.history.push({
                          state: {}
                        });
                      }}
                    >
                      {submitCount > 0 && isSubmitted ? (
                        <Result
                          className={styles.Wrapper}
                          status="success"
                          title={t(feedbackCopy.thankYou)}
                          subTitle={t(feedbackCopy.nextSteps)}
                          extra={[
                            <Button
                              key="done"
                              type="primary"
                              onClick={() => Enterprise.history.push({ state: {} })}
                            >
                              {t(feedbackCopy.done)}
                            </Button>
                          ]}
                        />
                      ) : (
                        <Spin spinning={isSubmitting}>
                          <Form layout="vertical" className={styles.Wrapper}>
                            <Input name="base64Content" hidden={true} />
                            <Paragraph>{t(feedbackCopy.description)}</Paragraph>
                            <Form.Item name="dateRange" label={t(feedbackCopy.date)}>
                              <RangePicker format={dateFormat} name="dateRange" size="large" />
                            </Form.Item>
                            <Form.Item name="text" label={t(feedbackCopy.feedback)}>
                              <TextArea name="text" rows={4} size="large" style={{ width: "100%" }} />
                            </Form.Item>
                            <Space>
                              <Button htmlType="submit" type="primary">
                                {t(feedbackCopy.submit)}
                              </Button>
                              {isMobile && (
                                <Button onClick={() => Enterprise.history.push({ state: {} })}>
                                  {t(feedbackCopy.cancel)}
                                </Button>
                              )}
                            </Space>
                          </Form>
                        </Spin>
                      )}
                    </Component>
                  )}
                </Formik>
              </EventListContext.Provider>
            </DataDictionaryContext.Provider>
          )}
        </MediaQueryDynamicImport>
      </div>
    ) : null;
  }

  return null;
};
