import { FC, useEffect, useRef, useState } from "react";
import * as _ from "lodash";
import Draggable from "react-draggable";

import {
  AvatarList,
  BulbOutlined,
  Icon,
  Space,
  TinyFab,
  TinyFabAction,
  Typography,
  Grid,
  Analysis
} from "@ctra/components";

import { Enterprise } from "@ctra/api";
import { classname } from "@ctra/utils";
import { Enterprise as Content, useTranslation } from "@ctra/i18n";
import { useGoogleAnalytics } from "@ctra/analytics";

import { CompiledRoutes } from "@routes";
import { GAActions } from "@base";
import { useFarm, useFarmList } from "@farms";
import { GAActions as AnalyticsGAActions } from "@analytics";
import { GAActions as InsightGAActions } from "@insights";

import { GAActions as TimelineGAActions, EventListContext, useCreateEventDialog } from "@events";

import styles from "./FAB.module.less";

const { Text } = Typography;

/**
 * Floating action button for quick navigation
 * @return {JSX.Element}
 * @constructor
 */
export const FAB: FC = () => {
  const { t } = useTranslation();
  const { trackEvent } = useGoogleAnalytics();
  const { farm } = useFarm();
  const { md } = Grid.useBreakpoint();
  const { farmList } = useFarmList();

  const [fabOpen, setFabOpen] = useState<boolean>();
  const [closedWhileDragging, setClosedWhileDragging] = useState<boolean>(false);
  const [recentlyDragged, setRecentlyDragged] = useState<boolean>(false);
  const fabRef = useRef<HTMLButtonElement | null>(null);

  const {
    api: { open }
  } = useCreateEventDialog();

  const {
    app: {
      analytics: { compareCharts: compareChartsRoute },
      insights: { manage: enableKPI }
    }
  } = CompiledRoutes;

  const {
    navigation: {
      fab: { activateInsight, compareCharts, addEvent }
    }
  } = Content;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (fabOpen && !fabRef.current?.contains(event.target as Node)) {
        setFabOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fabOpen]);

  /**
   * Only show if there is at least one farm present
   */
  if (_.isEmpty(farmList)) {
    return null;
  }

  return (
    <Draggable
      grid={[20, 20]}
      handle=".rtf--mb"
      onStart={(e) => {
        if (fabOpen) {
          setFabOpen(false);
          setClosedWhileDragging(true);
        }
      }}
      onDrag={() => {
        setRecentlyDragged(true);
      }}
      onStop={(e) => {
        if (closedWhileDragging) {
          setFabOpen(true);
          setClosedWhileDragging(false);
        }

        if (!md) {
          // @ts-ignore
          e.srcElement?.click?.();
        }
      }}
    >
      <div className={classname("crta-enterprise-fab", styles.FABWrapper)}>
        <TinyFab
          // @ts-ignore
          className={`rtf ${fabOpen ? "open" : "closed"}`}
          ref={fabRef}
          style={{ bottom: 0, right: 0 }}
          event="click"
          icon={<div className={styles.Plus} />}
          onClick={() => {
            if (recentlyDragged) {
              trackEvent(GAActions.dragFab);
              setRecentlyDragged(false);
            } else {
              trackEvent(fabOpen ? GAActions.closeFab : GAActions.openFab);
              setFabOpen(!fabOpen);
            }
          }}
        >
          {farm?.id && (
            <EventListContext.Provider farmID={farm.id}>
              <EventListContext.Consumer>
                {({ meta: { hash } }) => (
                  <TinyFabAction
                    onClick={() => {
                      trackEvent(TimelineGAActions.openAddEvent, {
                        label: _.defaultTo(farm?.name, void 0)
                      });

                      open(hash, farm?.id);
                      setFabOpen(!fabOpen);
                    }}
                  >
                    <Space>
                      <Icon component={AvatarList} />
                      <Text>{t<string>(addEvent)}</Text>
                    </Space>
                  </TinyFabAction>
                )}
              </EventListContext.Consumer>
            </EventListContext.Provider>
          )}
          <TinyFabAction
            onClick={() => {
              trackEvent(AnalyticsGAActions.goToCompareCharts);
              Enterprise.history.push(compareChartsRoute.index());
              setFabOpen(!fabOpen);
            }}
          >
            <Space>
              <Icon component={Analysis} />
              <Text>{t<string>(compareCharts)}</Text>
            </Space>
          </TinyFabAction>
          <TinyFabAction
            onClick={() => {
              trackEvent(InsightGAActions.goToEnableInsight);
              Enterprise.history.push(
                farm?.id ? enableKPI.farm.enable({ farmID: farm.id }) : enableKPI.index()
              );
              setFabOpen(!fabOpen);
            }}
          >
            <Space>
              <BulbOutlined />
              <Text>{t<string>(activateInsight)}</Text>
            </Space>
          </TinyFabAction>
        </TinyFab>
      </div>
    </Draggable>
  );
};
